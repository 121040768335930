<template>
  <div class="navbar-container">
    <v-container fluid>
      <v-row>
        <v-col>
          <div class="display-flex align-items-center">
            <div>
              <v-btn
                @click="showSidebar"
                icon
                dark
              >
                <v-icon
                  color="#FFFFFF"
                  size="25px"
                >
                  mdi-menu
                </v-icon>
              </v-btn>
            </div>
            <v-spacer></v-spacer>
            <div class="display-flex align-items-center">
              <v-menu
                v-if="notifications.length > 0"
                content-class="menu-arrow"
                transition="slide-x-transition"
                bottom
                right
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    dark
                  >
                    <v-badge
                      :content="countNotifications"
                      :value="countNotifications"
                      overlap
                      color="red"
                    >
                      <v-icon
                        color="#FFFFFF"
                        size="25px"
                      >
                        mdi-bell-outline
                      </v-icon>
                    </v-badge>
                  </v-btn>
                </template>

                <v-list
                  style="margin-top: -11px; overflow: auto;"
                  min-width="320px"
                  max-height="350px"
                  class="pa-0"
                >
                  <v-list-item
                    @click="markAsReadNotification(item.sNotificationId)"
                    class="pa-0"
                    style="min-height: 0px"
                    v-for="(item, index) in notifications"
                    :key="index"
                  >
                    <v-list-item-title>
                      <div :class="(item.bRead == false) ? 'view-not display-flex align-items-center' : 'no-view-not display-flex align-items-center'">
                        <div :class="(item.bRead) ? 'rounded-icon' : 'not-rounded-icon'">
                          <v-icon color="#283C4D">
                            mdi-shopping-outline
                          </v-icon>
                        </div>
                        <div class="ml-2">
                          <p class="text-menu-title mon-semibold">{{ item.sTitle }}</p>
                          <p class="text-menu-description mon-regular">{{ item.sMessage }}</p>
                          <p class="text-menu-date mon-regular mt-1">{{ formatDate(item.created_at) }}</p>
                        </div>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                icon
                v-else
                dark
                @click="noNotifications"
              >
                <!-- <v-badge
                  content="0"
                  value="0"
                  overlap
                  color="red"
                > -->
                <v-icon
                  color="#FFFFFF"
                  size="25px"
                >
                  mdi-bell-outline
                </v-icon>
                <!-- </v-badge> -->
              </v-btn>
              <div
                class="circle-ext ml-5"
                @click="redirectHome"
              >
                <div class="circle-int">
                  <p class="circle-text mon-regular">{{ sFullName }}</p>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",
  data() {
    return {
      //VARIABLES
      notifications: [],
      countNotifications: 0,
    };
  },
  beforeMount() {
    this.getNotifications();
    this.getNotificationsCount();
  },
  methods: {
    noNotifications: function () {
      if (this.selectLanguage == "sp") {
        this.mixInfo("Aún no hay notificaciones.");
      } else {
        this.mixInfo("There are no notifications yet.");
      }
    },
    redirectHome: function () {
      this.$router.push({name:"UserProfile"}).catch(() => {});
    },
    showSidebar: function () {
      this.$store.commit("setShowSidebar", true);
    },
    formatDate: function (date) {
      var new_date = MOMENT(date, "YYYY/MM/DD");
      var format_date = MOMENT(new_date).format("MMM. DD, YYYY.");
      return format_date;
    },
    getNotifications: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/users/${this.$store.state.sUserId}/notifications`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.notifications = response.data.results;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit("setToken", "");
          }
        });
    },
    getNotificationsCount: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/users/${this.$store.state.sUserId}/notifications/count`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.countNotifications = response.data.results.iUnread;
        })
        .catch((error) => {
        });
    },
    markAsReadNotification: function (id) {
      const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        },
        payload = {
          bRead: true,
        };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/users/${this.$store.state.sUserId}/notifications/${id}`,
        payload,
        config
      )
        .then((response) => {
          this.getNotifications();
          this.getNotificationsCount();
        })
        .catch((error) => {
        });
    },
  },
  computed: {
    sFullName: function () {
      var text = "";
      if (this.$store.state.sFullName.split(" ").length == 4) {
        text =
          this.$store.state.sFullName.split(" ")[0].charAt(0) +
          this.$store.state.sFullName.split(" ")[2].charAt(0);
      } else if (this.$store.state.sFullName.split(" ").length == 3) {
        text =
          this.$store.state.sFullName.split(" ")[0].charAt(0) +
          this.$store.state.sFullName.split(" ")[1].charAt(0);
      } else if (this.$store.state.sFullName.split(" ").length == 2) {
        text =
          this.$store.state.sFullName.split(" ")[0].charAt(0) +
          this.$store.state.sFullName.split(" ")[1].charAt(0);
      }
      return text;
    },
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getNotificationsCount();
        this.getNotifications();
      }
    },
  },
};
</script>

<style scoped>
.menu-arrow {
  margin-top: 55px;
  contain: initial;
  overflow: visible;
}

.menu-arrow::before {
  position: absolute;
  content: "";
  top: 0;
  right: 49px;
  transform: translateY(-170%);
  width: 10px;
  height: 13px;
  margin-right: 15px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 13px solid #fff;
}

.view-not {
  background-color: #e9f5f1;
  padding: 10px;
}

.no-view-not {
  background-color: #ffffff;
  padding: 10px;
}

.text-menu-title {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0px;
}

.text-menu-description {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 220px;
}

.text-menu-date {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #2e9e6f;
  opacity: 1;
  margin-bottom: 0px;
}

.rounded-icon {
  width: 45px;
  height: 45px;
  background: transparent linear-gradient(45deg, #fffc81 0%, #7eff86 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.not-rounded-icon {
  width: 45px;
  height: 45px;
  background: transparent;
  border: 1px solid #283c4d;
  border-radius: 30px;
  opacity: 1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-ext {
  background-color: #151d27;
  border: 1px solid #9dfe85;
  width: 41px;
  height: 41px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-ext:hover {
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.8;
}

.circle-int {
  background: transparent linear-gradient(10deg, #fffc81 0%, #7eff86 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 0px 11px 11px 0px;
  opacity: 1;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-text {
  text-align: center;
  font-size: 17px;
  letter-spacing: 0px;
  color: #283c4d;
  margin-bottom: 0px;
}

.navbar-container {
  background: #151d27 0% 0% no-repeat padding-box;
  opacity: 1;
}
</style>